<template>
  <div class="lg:mx-8 mx-5 md:py-10 py-7">
    <p class="text-eggplant font-semibold text-xl capitalize md:mb-7 mb-5">{{ $t('received products') }}</p>
    <!-- WEB VIEW -->
    <div class="md:block hidden">
      <header-row class="text-sm" :header="header"/>
      <div class="my-4">
        <div v-for="(item, index) in products" :key="index" class="card-row">
          <div :class="`card-row-header mt-3 p-3 grid-cols-${header.length}`">
            <div class="mx-1">
              <img @click="item.image_urls.length > 0 ? zoomImage(item.image_urls) : ''" class="product-img w-14 h-14 m-auto" :src="item.image_urls ? item.image_urls[0] : ''" alt="product image">
            </div>
            <p class="mx-1">{{ item.name }}</p>
            <p class="mx-1">{{ item.id }}</p>
            <p class="mx-1 truncate">{{ item.category ? item.category.name : '' }}</p>
            <p class="mx-1">{{ item.created ? item.created.split('T')[0] : 'N/A' }}</p>
            <p class="mx-1">{{ item.custom_fees ? item.custom_fees : 0 +  ' ' + $t('EGP') }}</p>
            <p class="mx-1">{{ item.store ? item.store.name : 'N/A' }}</p>
            <p class="mx-1">{{ item.shipment ? item.shipment.id : 'N/A' }}</p>
            <div class="flex items-start justify-center items-center px-1" :class="item.shipment ? item.shipment.status : ''">
              <p class="status-border"></p>
              <p class="capitalize ml-1 w-full">{{ item.shipment && item.shipment.status ? item.shipment.status.split('_').join(' ') : '' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE VIEW -->
    <div class="md:hidden block">
      <div v-for="(item, index) in products" :key="index" class="relative card-row mt-3 px-2 py-3">
        <div class="flex items-center">
          <div class="w-24 h-20">
            <img @click="item.image_urls.length > 0 ? zoomImage(item.image_urls) : ''" class="product-img w-full h-full" :src="item.image_urls ? item.image_urls[0] : ''" alt="product image">
          </div>
          <div class="ml-3 w-full text-left">
            <div class="flex justify-between">
              <div>
                <p class="mb-1">
                  <span class="font-medium text-xs">{{ item.name }}</span>
                  <span class="text-3xs text-darkgray"> {{ '( ' + item.box_number + ' )' }}</span>
                </p>
                <p class="text-eggplant text-2xs leading-tight font-normal">{{ item.category ? item.category.name : '' }}</p>
                <p class="font-normal text-2xs leading-tight">{{ item.store ? item.store.name : 'N/A' }}</p>
                <p class="font-normal text-2xs leading-tight">{{ item.received_date }}</p>
              </div>
              <div class="text-2xs font-normal mt-1 w-20" :class="item.shipment ? item.shipment.status : ''">
                <span class="status-border"></span>
                <span class="capitalize ml-1">{{ item.shipment && item.shipment.status ? item.shipment.status.split('_').join(' ') : ''}}</span>
              </div>
            </div>
            <div class="flex justify-between mt-1">
              <div>
                <p class="text-darkgray font-normal text-3xs">{{ $t('Custom Fees') }}</p>
                <p class="text-blue text-2xs">{{ item.custom_fees ? item.custom_fees : 0 +  ' ' + $t('EGP')}}</p>
              </div>
              <div class="w-20">
                <p class="text-darkgray font-normal text-3xs">{{ $t('Tracking Number') }}</p>
                <p class="text-blue text-2xs">{{ item.shipment ? item.shipment.id : 'N/A' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderRow from '@/layouts/customer/components/HeaderRow.vue'
import { sendRequest } from '@/axios/requestHelper'
import common from '@/assets/utils/common.js'

export default {
  data () {
    return {
      header: ['Product Image', 'Product Name', 'Product ID', 'Category', 'Received Date', 'Custom Fees', 'Online Store', 'Outgoing Shipment', 'Status'],
      products: [
        {
          category: {},
          store: {}
        }
      ]
    }
  },
  components: {
    HeaderRow
  },
  methods: {
    zoomImage (url) {
      common.zoomImage(url)
    },
    loadProducts () {
      sendRequest(this, 'api/products/', 'get', null, true,
        (response) => {
          this.products = response.data
        }
      )
    }
  },
  created () {
    this.loadProducts()
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/my-shipblu-view.scss"></style>