<template>
  <div :class="`header py-4 px-3 grid grid-cols-${header.length} divide-x divide-blue-900`">
    <div class="text-center font-medium text-darkblue leading-tight"
      v-for="(item, index) in header" :key="index" 
      :class="($route.name === 'outgoing-shipments' && index === 0) ? 'pl-3' : ''">
      <p class="mx-1">{{ $t(item) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['header'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/my-shipblu-view.scss"></style>