import { render, staticRenderFns } from "./ReceivedProducts.vue?vue&type=template&id=75121ebd&scoped=true&"
import script from "./ReceivedProducts.vue?vue&type=script&lang=js&"
export * from "./ReceivedProducts.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/styles/my-shipblu-view.scss?vue&type=style&index=0&id=75121ebd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75121ebd",
  null
  
)

export default component.exports